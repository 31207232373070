/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.scss in this case)
import './styles/app.scss';



global.$ = global.jQuery = require('jquery');

require('datatables.net');
var datatablesFr = require("./datatables-fr.json");

$(".datatable").DataTable({
    language: datatablesFr
});

var scrolling1 = false;
window.addEventListener('scroll', function() {
    scrolling1 = true;
});

setInterval(function () {
    if (scrolling1) {
        scrolling1 = false;
        scrollDetection();
    }
}, 200);

function scrollDetection() {
    console.log('scrolled', window.scrollY);
    if(window.scrollY == 0) {
        document.querySelector('body').classList.remove('scrolled')
    }
    else {
        document.querySelector('body').classList.add('scrolled')
    }
}

var noanchor = document.querySelectorAll('.noanchor');
for(var i = 0; i < noanchor.length; i++) {
    noanchor[i].addEventListener('click', function(e) {e.preventDefault();});
}

document.querySelector('#mobilemenu-open').addEventListener('click', function(){
    document.querySelector('body > header > .container').classList.toggle('open');
});


$(function(){

    /*
    var $pushtitle = $(".homejumbo .push h2");
    var $pushdesc = $(".homejumbo .push p.desc");
    var $pushlink = $(".homejumbo .push a");
    var examples = [
        [
            "Titre",
            "Soustitre",
            "Lien"
        ],
        [
            "Un élément mis en avant",
            "Une punchline, un concert à venir, you decide.<br>Ça doit rester court.",
            "Lien vers les détails"
        ],
        [
            "Concert d'Halloween",
            "Le 31 octobre au cimetière du Père Lachaise",
            "En savoir plus"
        ],
        [
            "Covid 19",
            "Le pass sanitaire est exigé<br> pour toutes les répétitions et les événements",
            "Informations"
        ],
        [
            "Répétition exceptionnelle",
            "Collaboration avec le Philharmonique de Berlin",
            "Mais comment ?"
        ],
        [
            "Un orchestre",
            "Des étudiants tourangeaux, mais pas que.",
            "Qui sommes-nous ?"
        ],
        [
            "On recrute !",
            "Si vous jouez du cor ou de la guimbarde,",
            "Rejoignez-nous !"
        ],
    ];
    var rnd = Math.floor(Math.random() * examples.length);
    $pushtitle.html(examples[rnd][0]);
    $pushdesc.html(examples[rnd][1]);
    $pushlink.html(examples[rnd][2]);
    //console.log($pushtitle, examples, rnd);
    */

    $("#connect button").on('click', function(){
        $("#connect").hide();
        $("#connected").show();
    });
    $("#disconnect a").on('click', function(){
        $("#connect").show();
        $("#connected").hide();
    });
    $("#connected").hide();

    rnd = Math.random();
    if(rnd < 0.5)
        $(".homejumbo").addClass('a');

    $(".event").on('click', function(e) {
        console.log('click');
        $(".event").removeClass('open');
        $(e.currentTarget).addClass('open');
    });
});
